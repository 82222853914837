/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ProductActionTypes from './product.types';

export const getAllList = (data) => ({
	type: ProductActionTypes.PRODUCT_LIST_START,
	payload: data,
});

export const getAllListSuccess = (data) => ({
	type: ProductActionTypes.PRODUCT_LIST_SUCCESS,
	payload: data,
});

export const getAllListFailure = (error) => ({
	type: ProductActionTypes.PRODUCT_LIST_FAILURE,
	payload: error,
});

export const getAllListChanged = () => ({
	type: ProductActionTypes.PRODUCT_LIST_CHANGED,
});

export const getAllListReset = () => ({
	type: ProductActionTypes.PRODUCT_LIST_RESET,
});

export const getFilterListStart = (data) => ({
	type: ProductActionTypes.PRODUCT_GET_FILTER_SEARCH_LIST,
	payload: data,
});

// CREATE SECTION
export const productCreateStart = (data) => ({
	type: ProductActionTypes.PRODUCT_CREATE_START,
	payload: data,
});

export const productCreateSuccess = (data) => ({
	type: ProductActionTypes.PRODUCT_CREATE_SUCCESS,
	payload: data,
});

export const productCreateFailure = (error) => ({
	type: ProductActionTypes.PRODUCT_CREATE_FAILURE,
	payload: error,
});

export const productCreateChanged = () => ({
	type: ProductActionTypes.PRODUCT_CREATE_CHANGED,
});

export const productCreateReset = () => ({
	type: ProductActionTypes.PRODUCT_CREATE_RESET,
});

// UPDATE SECTION
export const productUpdateStart = (data) => ({
	type: ProductActionTypes.PRODUCT_UPDATE_START,
	payload: data,
});

export const productUpdateSuccess = (data) => ({
	type: ProductActionTypes.PRODUCT_UPDATE_SUCCESS,
	payload: data,
});

export const productUpdateFailure = (error) => ({
	type: ProductActionTypes.PRODUCT_UPDATE_FAILURE,
	payload: error,
});

export const productUpdateChanged = () => ({
	type: ProductActionTypes.PRODUCT_UPDATE_CHANGED,
});

export const productUpdateReset = () => ({
	type: ProductActionTypes.PRODUCT_UPDATE_RESET,
});

// DELETE SECTION
export const productDeleteStart = (data) => ({
	type: ProductActionTypes.PRODUCT_DELETE_START,
	payload: data,
});

export const productDeleteSuccess = (data) => ({
	type: ProductActionTypes.PRODUCT_DELETE_SUCCESS,
	payload: data,
});

export const productDeleteFailure = (error) => ({
	type: ProductActionTypes.PRODUCT_DELETE_FAILURE,
	payload: error,
});

export const productDeleteChanged = () => ({
	type: ProductActionTypes.PRODUCT_DELETE_CHANGED,
});

export const productDeleteReset = () => ({
	type: ProductActionTypes.PRODUCT_DELETE_RESET,
});

// FETCH SECTION
export const productFetchStart = (data) => ({
	type: ProductActionTypes.PRODUCT_FETCH_START,
	payload: data,
});

export const productFetchSuccess = (data) => ({
	type: ProductActionTypes.PRODUCT_FETCH_SUCCESS,
	payload: data,
});

export const productFetchFailure = (error) => ({
	type: ProductActionTypes.PRODUCT_FETCH_FAILURE,
	payload: error,
});

export const productFetchChanged = () => ({
	type: ProductActionTypes.PRODUCT_FETCH_CHANGED,
});

export const productFetchReset = () => ({
	type: ProductActionTypes.PRODUCT_FETCH_RESET,
});

// product name update
export const productNameUpdateStart = (data) => ({
	type: ProductActionTypes.PRODUCT_NAME_CHANGE_START,
	payload: data,
});

export const productNameUpdateSuccess = (data) => ({
	type: ProductActionTypes.PRODUCT_NAME_CHANGE_SUCCESS,
	payload: data,
});

export const productNameUpdateFailure = (error) => ({
	type: ProductActionTypes.PRODUCT_NAME_CHANGE_FAILURE,
	payload: error,
});

export const productNameUpdateReset = () =>({
	type:ProductActionTypes.PRODUCT_NAME_CHANGE_RESET,
})
export const productNameUpdateChanged = () =>({
	type:ProductActionTypes.PRODUCT_NAME_CHANGE_CHANGED,
})