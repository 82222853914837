/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import dashboardActionTypes from './dashboard.types';

export const dashboardLoadStart = (data) => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_START,
	payload: data,
});

export const dashboardLoadSuccess = (data) => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_SUCCESS,
	payload: data,
});

export const dashboardLoadFailure = (data) => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_FAILURE,
	payload: data,
});

export const dashboardLoadChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_CHANGED,
});

export const dashboardLoadReset = () => ({
	type: dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_RESET,
});

// COLLECTION SECTION
export const dashboardCollectionStart = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_START,
	payload: data,
});

export const dashboardCollectionSuccess = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_SUCCESS,
	payload: data,
});

export const dashboardCollectionFailure = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_FAILURE,
	payload: data,
});

export const dashboardCollectionChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_CHANGED,
});

export const dashboardCollectionReset = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_RESET,
});

// COLLECTION SECTION TABLE
export const dashboardCollectionTableStart = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_START,
	payload: data,
});

export const dashboardCollectionTableSuccess = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_SUCCESS,
	payload: data,
});

export const dashboardCollectionTableFailure = (data) => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_FAILURE,
	payload: data,
});

export const dashboardCollectionTableChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_CHANGED,
});

export const dashboardCollectionTableReset = () => ({
	type: dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_RESET,
});

/** ***************** DASHBOARD BRANCH COLLECTION START *********************** */
export const getDashboardBranchCollectionStart = (getData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_START,
	payload: getData,
});

export const getDashboardBranchCollectionSuccess = (successData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_SUCCESS,
	payload: successData,
});

export const getDashboardBranchCollectionFailure = (failureData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_FAILURE,
	payload: failureData,
});

export const getDashboardBranchCollectionReset = () => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_RESET,
});

export const getDashboardBranchCollectionChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_CHANGED,
});
/** *****************  DASHBOARD BRANCH COLLECTION END *********************** */

/** ***************** DASHBOARD BRANCH MEMBER COLLECTION START *********************** */
export const getDashboardBranchMemberCollectionStart = (getData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_START,
	payload: getData,
});

export const getDashboardBranchMemberCollectionSuccess = (successData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_SUCCESS,
	payload: successData,
});

export const getDashboardBranchMemberCollectionFailure = (failureData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_FAILURE,
	payload: failureData,
});

export const getDashboardBranchMemberCollectionReset = () => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_RESET,
});

export const getDashboardBranchMemberCollectionChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_CHANGED,
});
/** *****************  DASHBOARD BRANCH MEMBER COLLECTION END *********************** */

/** ***************** DASHBOARD BRANCH YETTOCOLLECT MEMBER START *********************** */
export const getDashboardBranchYetToCollectMemberStart = (getData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_START,
	payload: getData,
});

export const getDashboardBranchYetToCollectMemberSuccess = (successData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_SUCCESS,
	payload: successData,
});

export const getDashboardBranchYetToCollectMemberFailure = (failureData) => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_FAILURE,
	payload: failureData,
});

export const getDashboardBranchYetToCollectMemberReset = () => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_RESET,
});

export const getDashboardBranchYetToCollectMemberChanged = () => ({
	type: dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_CHANGED,
});
/** *****************  DASHBOARD BRANCH YETTOCOLLECT MEMBER END *********************** */