/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import dashboardActionTypes from './dashboard.types';
import generalTypesAction from '../genera.types';
import { getEndodeJsonData } from '../common/common.utils';

const INITIAL_STATE = {
	dashboardLoadStatus: null,
	dashboardLoadResponse: null,

	// COLLECTION SECTION
	dashboardCollectionStatus: null,
	dashboardCollectionResponse: null,

	// COLLECTION SECTION TABLE
	dashboardCollectionTableStatus: null,
	dashboardCollectionTableResponse: null,

	// BRANCH COLLECTION SECTION TABLE
	dashboardBranchCollectionTableStatus: null,
	dashboardBranchCollectionTableResponse: null,

	// BRANCH MEMBER COLLECTION SECTION TABLE
	dashboardBranchMemberCollectionTableStatus: null,
	dashboardBranchMemberCollectionTableResponse: null,

	// BRANCH YETTOCOLLECT MEMBER SECTION TABLE
	dashboardBranchYetToCollectMemberTableStatus: null,
	dashboardBranchYetToCollectMemberTableResponse: null,
};

// eslint-disable-next-line default-param-last
const dashboardReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_SUCCESS:
			return {
				...state,
				dashboardLoadResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DASHBOARD,
				),
				dashboardLoadStatus: 'Success',
			};
		case dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_FAILURE:
			return {
				...state,
				dashboardLoadResponse: null,
				dashboardLoadStatus: 'Failure',
			};
		case dashboardActionTypes.DASHBOARD_LOAD_REPSONSE_CHANGED:
			return { ...state, dashboardLoadStatus: null };

		case dashboardActionTypes.DASHBOARD_COLLECTION_SUCCESS:
			return {
				...state,
				dashboardCollectionResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DASHBOARD,
				),
				dashboardCollectionStatus: 'Success',
			};
		case dashboardActionTypes.DASHBOARD_COLLECTION_FAILURE:
			return {
				...state,
				dashboardCollectionResponse: null,
				dashboardCollectionStatus: 'Failure',
			};
		case dashboardActionTypes.DASHBOARD_COLLECTION_CHANGED:
			return { ...state, dashboardCollectionStatus: null };

		case dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_SUCCESS:
			return {
				...state,
				dashboardCollectionTableResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DASHBOARD,
				),
				dashboardCollectionTableStatus: 'Success',
			};
		case dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_FAILURE:
			return {
				...state,
				dashboardCollectionTableResponse: null,
				dashboardCollectionTableStatus: 'Failure',
			};
		case dashboardActionTypes.DASHBOARD_COLLECTION_TABLE_CHANGED:
			return { ...state, dashboardCollectionTableStatus: null };

		case dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_SUCCESS:
			return {
				...state,
				dashboardBranchCollectionTableResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DASHBOARDBRANCHDATA,
				),
				dashboardBranchCollectionTableStatus: 'Success',
			};
		case dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_FAILURE:
			return {
				...state,
				dashboardBranchCollectionTableResponse: null,
				dashboardBranchCollectionTableStatus: 'Failure',
			};
		case dashboardActionTypes.DASHBOARD_BRANCH_COLLECTION_CHANGED:
			return { ...state, dashboardBranchCollectionTableStatus: null };

		case dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_SUCCESS:
			return {
				...state,
				dashboardBranchMemberCollectionTableResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DASHBOARDBRANCHMEMBERDATA,
				),
				dashboardBranchMemberCollectionTableStatus: 'Success',
			};
		case dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_FAILURE:
			return {
				...state,
				dashboardBranchMemberCollectionTableResponse: null,
				dashboardBranchMemberCollectionTableStatus: 'Failure',
			};
		case dashboardActionTypes.DASHBOARD_BRANCH_MEMBER_COLLECTION_CHANGED:
			return { ...state, dashboardBranchMemberCollectionTableStatus: null };

		case dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_SUCCESS:
			return {
				...state,
				dashboardBranchYetToCollectMemberTableResponse: getEndodeJsonData(
					action.payload,
					generalTypesAction.DASHBOARDYETTOCOLLECTMEMBERDATA,
				),
				dashboardBranchYetToCollectMemberTableStatus: 'Success',
			};
		case dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_FAILURE:
			return {
				...state,
				dashboardBranchYetToCollectMemberTableResponse: null,
				dashboardBranchYetToCollectMemberTableStatus: 'Failure',
			};
		case dashboardActionTypes.DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_CHANGED:
			return { ...state, dashboardBranchYetToCollectMemberTableStatus: null };

		default:
			return state;
	}
};

export default dashboardReducer;
