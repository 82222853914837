const dashboardActionTypes = {
	DASHBOARD_LOAD_REPSONSE_START: 'DASHBOARD_LOAD_REPSONSE_START',
	DASHBOARD_LOAD_REPSONSE_SUCCESS: 'DASHBOARD_LOAD_REPSONSE_SUCCESS',
	DASHBOARD_LOAD_REPSONSE_FAILURE: 'DASHBOARD_LOAD_REPSONSE_FAILURE',
	DASHBOARD_LOAD_REPSONSE_CHANGED: 'DASHBOARD_LOAD_REPSONSE_CHANGED',
	DASHBOARD_LOAD_REPSONSE_RESET: 'DASHBOARD_LOAD_REPSONSE_RESET',

	// COLLECTION SECTION
	DASHBOARD_COLLECTION_START: 'DASHBOARD_COLLECTION_START',
	DASHBOARD_COLLECTION_SUCCESS: 'DASHBOARD_COLLECTION_SUCCESS',
	DASHBOARD_COLLECTION_FAILURE: 'DASHBOARD_COLLECTION_FAILURE',
	DASHBOARD_COLLECTION_CHANGED: 'DASHBOARD_COLLECTION_CHANGED',
	DASHBOARD_COLLECTION_RESET: 'DASHBOARD_COLLECTION_RESET',

	
	// COLLECTION SECTION TABLE
	DASHBOARD_COLLECTION_TABLE_START: 'DASHBOARD_COLLECTION_TABLE_START',
	DASHBOARD_COLLECTION_TABLE_SUCCESS: 'DASHBOARD_COLLECTION_TABLE_SUCCESS',
	DASHBOARD_COLLECTION_TABLE_FAILURE: 'DASHBOARD_COLLECTION_TABLE_FAILURE',
	DASHBOARD_COLLECTION_TABLE_CHANGED: 'DASHBOARD_COLLECTION_TABLE_CHANGED',
	DASHBOARD_COLLECTION_TABLE_RESET: 'DASHBOARD_COLLECTION_TABLE_RESET',

	// BRANCH COLLECTION SECTION TABLE
	DASHBOARD_BRANCH_COLLECTION_START: 'DASHBOARD_BRANCH_COLLECTION_START',
	DASHBOARD_BRANCH_COLLECTION_SUCCESS: 'DASHBOARD_BRANCH_COLLECTION_SUCCESS',
	DASHBOARD_BRANCH_COLLECTION_FAILURE: 'DASHBOARD_BRANCH_COLLECTION_FAILURE',
	DASHBOARD_BRANCH_COLLECTION_RESET: 'DASHBOARD_BRANCH_COLLECTION_RESET',
	DASHBOARD_BRANCH_COLLECTION_CHANGED: 'DASHBOARD_BRANCH_COLLECTION_CHANGED',

	// BRANCH MEMBER COLLECTION SECTION TABLE
	DASHBOARD_BRANCH_MEMBER_COLLECTION_START: 'DASHBOARD_BRANCH_MEMBER_COLLECTION_START',
	DASHBOARD_BRANCH_MEMBER_COLLECTION_SUCCESS: 'DASHBOARD_BRANCH_MEMBER_COLLECTION_SUCCESS',
	DASHBOARD_BRANCH_MEMBER_COLLECTION_FAILURE: 'DASHBOARD_BRANCH_MEMBER_COLLECTION_FAILURE',
	DASHBOARD_BRANCH_MEMBER_COLLECTION_RESET: 'DASHBOARD_BRANCH_MEMBER_COLLECTION_RESET',
	DASHBOARD_BRANCH_MEMBER_COLLECTION_CHANGED: 'DASHBOARD_BRANCH_MEMBER_COLLECTION_CHANGED',

	// BRANCH MEMBER COLLECTION SECTION TABLE
	DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_START: 'DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_START',
	DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_SUCCESS: 'DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_SUCCESS',
	DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_FAILURE: 'DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_FAILURE',
	DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_RESET: 'DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_RESET',
	DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_CHANGED: 'DASHBOARD_BRANCH_YETTOCOLLECT_MEMBER_CHANGED',
};
export default dashboardActionTypes;
